import { createSlice } from '@reduxjs/toolkit';
import { fetchAppSubcriptionTypes } from './actions';
import { AppSubscriptionTypesState } from './types';
import { ApiAppSubscriptionType } from '../../../api/types/app';
import { Bookable } from '../../modules/products';
import { SipgateDomain } from '../../modules/userinfo';

const initialState: AppSubscriptionTypesState = {
	fetching: false,
	fetched: false,
	items: [],
};

const mapApiAppSubscriptionTypeToProductWithDescription = (
	apiAppSubscriptionType: ApiAppSubscriptionType,
	domain: SipgateDomain
): Bookable => ({
	id: apiAppSubscriptionType.name,
	name: apiAppSubscriptionType.name,
	cost: {
		amount: {
			amount: apiAppSubscriptionType.price,
			currency: domain === 'sipgate.de' ? 'EUR' : 'GBP',
			fraction: 10_000,
		},
		interval: 'monthly',
		isNetto: true,
	},
});

const slice = createSlice({
	name: 'sipgateAppSubscriptionTypes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAppSubcriptionTypes.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
			items: [],
		}));

		builder.addCase(fetchAppSubcriptionTypes.fulfilled, (state, { payload, meta }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload.items.map(item =>
				mapApiAppSubscriptionTypeToProductWithDescription(item, meta.arg)
			),
		}));
	},
});

export const reducer = slice.reducer;
