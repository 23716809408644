import { createSlice } from '@reduxjs/toolkit';
import { AccountState } from './types';
import {
	deleteLogo,
	fetchAccount,
	fetchEventLifetime,
	updateEventLifetime,
	uploadLogo,
} from './actions';

export const initialState = {
	fetching: false,
	fetched: false,
	uploadingLogo: false,
	uploadedLogo: false,
	uploadLogoError: false,
	deletingLogo: false,
	eventLifetime: {
		eventLifetime: 'NONE',
		fetching: false,
		fetched: false,
	},
} as AccountState;

export const slice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		verifyAccount: state => ({ ...state, verified: true }),
		resetUploadError: state => ({ ...state, uploadLogoError: false }),
		resetUploadSuccess: state => ({ ...state, uploadedLogo: false }),
	},
	extraReducers: builder => {
		builder.addCase(fetchAccount.pending, state => ({ ...state, fetching: true }));
		builder.addCase(fetchAccount.fulfilled, (state, action) => ({
			...state,
			fetching: false,
			fetched: true,
			...action.payload,
		}));

		builder.addCase(uploadLogo.pending, state => ({
			...state,
			uploadingLogo: true,
			uploadedLogo: false,
			uploadLogoError: false,
		}));
		builder.addCase(uploadLogo.fulfilled, state => ({
			...state,
			uploadingLogo: false,
			uploadedLogo: true,
			uploadLogoError: false,
		}));
		builder.addCase(uploadLogo.rejected, state => ({
			...state,
			uploadingLogo: false,
			uploadedLogo: false,
			uploadLogoError: true,
		}));

		builder.addCase(deleteLogo.pending, state => ({ ...state, deletingLogo: true }));
		builder.addCase(deleteLogo.fulfilled, state => ({
			...state,
			deletingLogo: false,
			logoUrl: '',
		}));

		builder.addCase(fetchEventLifetime.pending, state => ({
			...state,
			eventLifetime: { ...state.eventLifetime, fetching: true },
		}));

		builder.addCase(fetchEventLifetime.fulfilled, (state, action) => ({
			...state,
			eventLifetime: {
				...state.eventLifetime,
				fetching: false,
				fetched: true,
				eventLifetime: action.payload.eventLifetime,
			},
		}));

		builder.addCase(fetchEventLifetime.rejected, state => ({
			...state,
			eventLifetime: { ...state.eventLifetime, fetching: false, fetched: false },
		}));

		builder.addCase(updateEventLifetime.fulfilled, (state, action) => ({
			...state,
			eventLifetime: {
				...state.eventLifetime,
				eventLifetime: action.meta.arg,
			},
		}));
	},
});

export const reducer = slice.reducer;
export const { verifyAccount, resetUploadError, resetUploadSuccess } = slice.actions;
