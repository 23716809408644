import { useAction } from '../../index';
import { fetchProductTacs } from './actions';
import { useSelector } from '../../utils/wrapper';

export const useProductsTacs = () => {
	const tacs = useSelector(s => s.productsTacs.items);

	return {
		fetchProductTacs: useAction(fetchProductTacs),
		items: tacs,
	};
};
