import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	deleteLogo,
	fetchAccount,
	fetchEventLifetime,
	updateEventLifetime,
	uploadLogo,
} from './actions';
import { resetUploadError, resetUploadSuccess } from './reducer';
import { useAction } from '../../index';

export const useAccount = () => {
	const dispatch = useDispatch();
	const account = useSelector(state => state.account);

	useEffect(() => {
		dispatch(fetchAccount());
	}, [dispatch]);

	return account.fetched ? account : null;
};

export const useLogo = () => {
	const dispatch = useDispatch();

	return {
		deleteLogo: useAction(deleteLogo),
		uploadLogo: useCallback(
			(base64Content: string) => {
				return dispatch(uploadLogo(base64Content));
			},
			[dispatch]
		),
		uploading: useSelector(state => state.account.uploadingLogo),
		uploaded: useSelector(state => state.account.uploadedLogo),
		uploadError: useSelector(state => state.account.uploadLogoError),
		resetUploadError: useCallback(() => dispatch(resetUploadError()), [dispatch]),
		resetUploadSuccess: useCallback(() => dispatch(resetUploadSuccess()), [dispatch]),
	};
};

export const useEventLifetime = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchEventLifetime());
	}, [dispatch]);

	return {
		fetchEventLifetime: useAction(fetchEventLifetime),
		updateEventLifetime: useAction(updateEventLifetime),
		eventLifetime: useSelector(state => state.account.eventLifetime),
	};
};
