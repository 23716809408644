import { Store } from '@reduxjs/toolkit';
import { State } from '../state';
import { UpdateNumbersAction } from './types';
import { Phonenumber } from '../../../../redux/slices/phonenumbers';

export const updateNumbers = (store: Store<State>) => (numbers: Phonenumber[]) => {
	store.dispatch<UpdateNumbersAction>({
		type: 'UPDATE_NUMBERS',
		numbers,
	});
};
