import { NormalizedEvent } from '../normalize/events';
import { Contact } from '../../../redux/modules/contacts';
import { BlocklistEntry } from '../../../redux/slices/blocklist';
import { ApiLabel } from '../../../api/types/labels';
import { ExtensionState } from '../../../utils/endpoints';
import { SipgateDomain } from '../../../redux/modules/userinfo';
import { Phonenumber } from '../../../redux/slices/phonenumbers';

export const PAGE_SIZE_LOCALSTORAGE_KEY = 'history-page-size';

export interface State {
	pageSize: number;

	domain: SipgateDomain;
	contacts: Contact[];
	blocklist: BlocklistEntry[];
	extensions: ExtensionState;
	numbers: Phonenumber[];

	runningAudioPlayer: {
		eventId: string;
		playerId: string;
	} | null;

	labels: ApiLabel[] | null;

	entries: {
		error: boolean;
		tooManyEvents: boolean;
		abortController: AbortController | null;

		result: {
			normalized: NormalizedEvent[];
			totalCount: number;
		} | null;
	};
}

export const initialState: Omit<State, 'domain'> = {
	pageSize: parseInt(localStorage.getItem(PAGE_SIZE_LOCALSTORAGE_KEY)!, 10) || 25,

	contacts: [],
	blocklist: [],
	extensions: {
		users: [],
		devices: [],
		faxlines: [],
		voicemails: [],
		groups: [],
		phonelines: [],
		sms: [],
		conferenceRooms: [],
		acds: [],
	},
	numbers: [],

	runningAudioPlayer: null,

	labels: null,

	entries: {
		error: false,
		tooManyEvents: false,
		result: null,
		abortController: null,
	},
};
