import moment from 'moment/moment';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import {
	AppSubscriptionName,
	CouldNotFetchApp,
	CouldNotFetchApps,
	isSipgateAppWithSubscription,
	isSipgateFreeApp,
	isTrial,
	SipgateApp,
	SipgateAppError,
	SipgateAppWithSubscription,
} from './types';
import { instanaLog } from '../../../third-party/instana';
import { isBaseContract } from '../../modules/contracts';

export const fetchSipgateApps = createAsyncThunk<
	SipgateApp[],
	{
		forceFetch?: boolean;
	},
	{ rejectValue: SipgateAppError }
>('sipgateApp/fetch', async (_args: { forceFetch?: boolean }, { getState, rejectWithValue }) => {
	try {
		const sipgateApps = (await api.getSipgateApps()).items;
		const contracts = getState().contracts.items;
		const baseContract = contracts.find(isBaseContract);
		const isPreviewContract = baseContract?.preview;

		// This is only a workaround to fix the trial end date for preview contracts. Should be solved in the backend.
		return sipgateApps.map(app => {
			if (!isTrial(app)) {
				return app;
			}

			return {
				...app,
				activeBooking: {
					...app.activeBooking,
					trial: {
						endsAt: isPreviewContract
							? baseContract?.cancellationDate
							: app.activeBooking.trial.endsAt,
						isCancelled: app.activeBooking.trial.isCancelled,
					},
				},
			};
		});
	} catch (_ignore) {
		instanaLog.error('Failed to fetch sipgate apps');
		return rejectWithValue(CouldNotFetchApps());
	}
});

export const fetchSipgateApp = createAsyncThunk<
	SipgateApp,
	{
		webuserId: string;
		forceFetch?: boolean;
	},
	{ rejectValue: SipgateAppError }
>(
	'sipgateApp/fetchSingle',
	async ({ webuserId }: { webuserId: string; forceFetch?: boolean }, { rejectWithValue }) => {
		try {
			return await api.getSipgateApp(webuserId);
		} catch (error) {
			instanaLog.error('Failed to fetch sipgate app', error);
			return rejectWithValue(CouldNotFetchApp(webuserId));
		}
	},
	{
		condition: (
			{ webuserId, forceFetch = false }: { webuserId: string; forceFetch?: boolean },
			{ getState }
		) => {
			const alreadyFetched = getState().sipgateApp.fetchedForWebuser.includes(webuserId);
			return !alreadyFetched || forceFetch;
		},
	}
);

export const upgradedSipgateApp = createAsyncThunk(
	'sipgateApp/upgrade',
	(sipgateApp: SipgateAppWithSubscription) =>
		api.upgradeAppSubscription(sipgateApp.userId, sipgateApp.activeBooking.bookingId)
);

export const createSipgateApps = createAsyncThunk(
	'sipgateApp/create',
	async (
		{
			userIds,
			subscriptionType,
		}: {
			userIds: Set<string>;
			subscriptionType: Omit<'FREE', AppSubscriptionName>;
			isAdmin: boolean;
		},
		{ dispatch }
	) => {
		await api.createAppBookings(userIds, subscriptionType as string);
		dispatch(fetchSipgateApps({}));
	}
);

export const updateSipgateApps = createAsyncThunk(
	'sipgateApp/update',
	async (
		{
			userIds,
			subscriptionType,
		}: { userIds: string[]; subscriptionType: Omit<'FREE', AppSubscriptionName> },
		{ getState, dispatch }
	) => {
		const freeApps = getState().sipgateApp.items.filter(isSipgateFreeApp);
		const apps = getState().sipgateApp.items.filter(isSipgateAppWithSubscription);

		const createAppUserIds = freeApps
			.filter(app => userIds.includes(app.userId))
			.map(app => app.userId);
		if (createAppUserIds.length > 0) {
			await dispatch(
				createSipgateApps({
					userIds: new Set<string>(createAppUserIds),
					subscriptionType,
					isAdmin: true,
				})
			);
		}

		const upgradeApps = apps.filter(app => userIds.includes(app.userId));
		await Promise.all(upgradeApps.map(sipgateApp => dispatch(upgradedSipgateApp(sipgateApp))));
	}
);

export const cancelSipgateApp = createAsyncThunk(
	'sipgateApp/cancel',
	(sipgateApp: SipgateApp, { rejectWithValue }) => {
		if (!isSipgateAppWithSubscription(sipgateApp)) {
			return rejectWithValue('App not found');
		}

		if (isSipgateAppWithSubscription(sipgateApp) && isTrial(sipgateApp)) {
			return api.cancelAppTrial(sipgateApp.userId, sipgateApp.activeBooking.bookingId, true);
		}

		return api.cancelAppBooking(
			sipgateApp.userId,
			sipgateApp.activeBooking.bookingId,
			moment().endOf('month')
		);
	}
);

export const revokeCancelledSipgateApp = createAsyncThunk(
	'sipgateApp/revokeCancellation',
	(sipgateApp: SipgateApp, { rejectWithValue }) => {
		if (!isSipgateAppWithSubscription(sipgateApp)) {
			return rejectWithValue('App has no booking');
		}

		if (isTrial(sipgateApp)) {
			return api.revokeTrialCancellation(sipgateApp.userId, sipgateApp.activeBooking.bookingId);
		}

		return api.revokeCancellation(sipgateApp.userId, sipgateApp.activeBooking.bookingId);
	}
);
