import { useSelector } from '../../utils/wrapper';
import {
	fetchAccountStatement,
	fetchBillingAddress,
	fetchBillingFlags,
	fetchInvoices,
	fetchItemizedBillingEntries,
	fetchItemizedBillingSettings,
	recreateInvoice,
	updateBillingAddress,
	updateItemizedBillingSettings,
} from './actions';
import { useAction } from '../..';

export const useAccounting = () => {
	const accountStatement = useSelector(state => state.accounting.accountStatement);

	return {
		accountStatement: accountStatement.fetched ? accountStatement : null,
		invoices: useSelector(state => state.accounting.invoices),
		itemizedBill: useSelector(state => state.accounting.itemizedBill),
		billingAddress: useSelector(state => state.accounting.billingAddress),
		fetchAccountStatement: useAction(fetchAccountStatement),
		fetchBillingAddress: useAction(fetchBillingAddress),
		fetchItemizedBill: useAction(fetchItemizedBillingEntries),
		updateBillingAddress: useAction(updateBillingAddress),
		fetchInvoices: useAction(fetchInvoices),
		recreateInvoice: useAction(recreateInvoice),
		itemizedBillingSettings: useSelector(state => state.accounting.itemizedBillSettings),
		fetchItemizedBillingSettings: useAction(fetchItemizedBillingSettings),
		updateItemizedBillingSettings: useAction(updateItemizedBillingSettings),
		billingFlags: useSelector(state => state.accounting.billingFlags),
		fetchBillingFlags: useAction(fetchBillingFlags),
	};
};
