import { useEffect } from 'react';

import { useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	consentAffiliateLogin,
	createCommissionPayoutAccount,
	deleteCommissionPayoutAccount,
	fetchAffiliateInfo,
	fetchAffiliateLoginConsentStatus,
	fetchCommissionBalance,
	fetchCommissionPayoutAccount,
	fetchReferallComissions,
	fetchReferredCustomers,
	payoutCommissionBalance,
	revokeAffiliateLoginConsent,
} from './actions';

export const useReferredCustomers = () => {
	const dispatch = useDispatch();
	const affiliate = useSelector(state => state.affiliate);

	useEffect(() => {
		const firstDayOfThisYear = new Date(new Date().getFullYear(), 0, 1, 1, 0, 0, 0);

		dispatch(fetchReferredCustomers());
		dispatch(
			fetchReferallComissions({
				startDate: firstDayOfThisYear.toISOString(),
			})
		);
		dispatch(fetchCommissionBalance());
		dispatch(fetchCommissionPayoutAccount());
	}, [dispatch]);

	return {
		...{ ...affiliate, fetchedBalance: undefined, balance: undefined, fetchingBalance: undefined },
		fetchReferralCommissions: useAction(fetchReferallComissions),
		fetchCommissionBalance: useAction(fetchCommissionBalance),
	};
};

export const useCommissionBalance = () => {
	const dispatch = useDispatch();
	const { fetchedBalance, fetchingBalance, balance } = useSelector(state => state.affiliate);

	useEffect(() => {
		dispatch(fetchCommissionBalance());
	}, [dispatch]);

	return {
		fetchingBalance,
		fetchedBalance,
		balance,
		fetchCommissionBalance: useAction(fetchCommissionBalance),
		payoutCommissionBalance: useAction(payoutCommissionBalance),
	};
};

export const useAffiliateConsent = () => {
	const dispatch = useDispatch();
	const { fetchingConsent, fetchedConsent, consent } = useSelector(state => state.affiliate);

	useEffect(() => {
		dispatch(fetchAffiliateLoginConsentStatus());
	}, [dispatch]);

	return {
		fetchingConsent,
		fetchedConsent,
		consent,
		grantConsent: useAction(consentAffiliateLogin),
		revokeConsent: useAction(revokeAffiliateLoginConsent),
	};
};

export const useAffiliateInfo = () => {
	const dispatch = useDispatch();
	const { fetchingAffiliateInfo, fetchedAffiliateInfo, affiliateInfo } = useSelector(
		state => state.affiliate
	);

	useEffect(() => {
		dispatch(fetchAffiliateInfo());
	}, [dispatch]);

	return {
		fetchingAffiliateInfo,
		fetchedAffiliateInfo,
		affiliateInfo,
		fetchAffiliateInfo: useAction(fetchAffiliateInfo),
	};
};

export const useCommissionPayoutAccount = () => {
	const dispatch = useDispatch();
	const {
		fetchingCommissionPayoutAccount,
		fetchedCommissionPayoutAccount,
		commissionPayoutAccount,
	} = useSelector(state => state.affiliate);

	useEffect(() => {
		dispatch(fetchCommissionPayoutAccount());
	}, [dispatch]);

	return {
		fetchingCommissionPayoutAccount,
		fetchedCommissionPayoutAccount,
		commissionPayoutAccount,
		fetchCommissionPayoutAccount: useAction(fetchCommissionPayoutAccount),
		createCommissionPayoutAccount: useAction(createCommissionPayoutAccount),
		deleteCommissionPayoutAccount: useAction(deleteCommissionPayoutAccount),
	};
};
