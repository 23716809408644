import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';

const shouldFetchQuickDials = (state: ReduxState) =>
	!state.quickDials.fetched && !state.quickDials.fetching;

export const fetchQuickDials = createAsyncThunk(
	'quickDials/fetch',
	async () => api.getAllNumbers(),
	{
		condition: (_arg, { getState }) => shouldFetchQuickDials(getState()),
	}
);

export const createQuickDial = createAsyncThunk(
	'quickDials/create',
	async (data: { extensionId: string; number: string }) =>
		api.createQuickDialNumber(data.extensionId, data.number)
);

export const editQuickDial = createAsyncThunk(
	'quickDials/edit',
	async (data: { extensionId: string; numberId: string; number: string }) =>
		api.setQuickDialNumber(data.extensionId, data.numberId, data.number)
);

export const deleteQuickDial = createAsyncThunk(
	'quickDials/delete',
	async (data: { numberId: string }) => api.deleteQuickDialNumber(data.numberId)
);

export const routeQuickDial = createAsyncThunk(
	'quickDials/route',
	async (data: { extensionId: string; numberId: string }) =>
		api.routeQuickDial(data.numberId, data.extensionId)
);
