import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
	fetchAccountStatement,
	fetchBillingAddress,
	fetchBillingFlags,
	fetchInvoices,
	fetchItemizedBillingEntries,
	fetchItemizedBillingSettings,
	updateBillingAddress,
	updateItemizedBillingSettings,
} from './actions';
import { AccountingState, ApiAggregatedStatement, ApiStatement } from './types';

export const initialState: AccountingState = {
	itemizedBill: {
		entries: [],
		fetching: false,
		fetched: false,
	},
	itemizedBillSettings: {
		settings: {
			phoneNumberFormat: 'FULL',
		},
		fetching: false,
		fetched: false,
	},
	accountStatement: {
		statements: [],
		aggregatedStatements: [],
		balances: [],
		fetching: false,
		fetched: false,
	},
	invoices: {
		invoices: [],
		fetching: false,
		fetched: false,
	},
	billingFlags: {
		flags: { hasHighTrafficFlag: false },
		fetching: false,
		fetched: false,
	},
	billingAddress: {
		billingAddress: {
			gender: '',
			firstname: '',
			middleInitial: '',
			lastname: '',
			company: '',
			email: '',
			street: '',
			zip: '',
			city: '',
			state: '',
			country: '',
			address2: '',
			vatId: '',
		},
		fetching: false,
		fetched: false,
	},
};

export const slice = createSlice({
	name: 'accounting',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAccountStatement.pending, state => ({
			...state,
			accountStatement: {
				...state.accountStatement,
				fetching: true,
			},
		}));
		builder.addCase(fetchAccountStatement.fulfilled, (state, action) => {
			const existingStatements = state.accountStatement.statements.filter(
				stmt =>
					stmt.periodStart !== action.meta.arg.periodStart.toUTC().toMillis() &&
					stmt.periodEnd !== action.meta.arg.periodEnd.toUTC().toMillis()
			);
			const existingAggregatedStatements = state.accountStatement.aggregatedStatements.filter(
				stmt => {
					return (
						stmt.periodStart !== action.meta.arg.periodStart.toUTC().toMillis() &&
						stmt.periodEnd !== action.meta.arg.periodEnd.toUTC().toMillis()
					);
				}
			);

			return {
				...state,
				accountStatement: {
					fetching: false,
					fetched: true,
					statements: [
						...existingStatements,
						...action.payload.statements.map((statements: ApiStatement) => ({
							...statements,
							periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
							periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
						})),
					],
					aggregatedStatements: [
						...existingAggregatedStatements,
						...action.payload.aggregatedStatements.map((statements: ApiAggregatedStatement) => ({
							...statements,
							periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
							periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
						})),
					],
					balances: [
						...state.accountStatement.balances.filter(
							blnc =>
								!(
									blnc.periodStart === action.meta.arg.periodStart.toUTC().toMillis() &&
									blnc.periodEnd === action.meta.arg.periodEnd.toUTC().toMillis()
								)
						),
						{
							periodStart: action.meta.arg.periodStart.toUTC().toMillis(),
							periodEnd: action.meta.arg.periodEnd.toUTC().toMillis(),
							balanceStart: action.payload.balanceStart,
							balanceEnd: action.payload.balanceEnd,
						},
					],
				},
			};
		});
		builder.addCase(fetchAccountStatement.rejected, state => ({
			...state,
			accountStatement: {
				...state.accountStatement,
				fetching: false,
				fetched: false,
			},
		}));
		builder.addCase(fetchBillingAddress.pending, state => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				fetching: true,
			},
		}));
		builder.addCase(fetchBillingAddress.fulfilled, (state, action) => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				billingAddress: action.payload,
				fetching: false,
				fetched: true,
			},
		}));
		builder.addCase(fetchBillingAddress.rejected, state => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				fetching: false,
				fetched: false,
			},
		}));
		builder.addCase(updateBillingAddress.pending, state => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				fetching: true,
			},
		}));
		builder.addCase(updateBillingAddress.fulfilled, (state, action) => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				billingAddress: action.payload,
				fetching: false,
				fetched: true,
			},
		}));
		builder.addCase(updateBillingAddress.rejected, state => ({
			...state,
			billingAddress: {
				...state.billingAddress,
				fetching: false,
				fetched: false,
			},
		}));
		builder.addCase(fetchInvoices.pending, state => ({
			...state,
			invoices: {
				...state.invoices,
				fetching: true,
			},
		}));
		builder.addCase(fetchInvoices.fulfilled, (state, action) => ({
			...state,
			invoices: {
				invoices: action.payload.invoices,
				fetching: false,
				fetched: true,
			},
		}));
		builder.addCase(fetchInvoices.rejected, state => ({
			...state,
			invoices: {
				...state.invoices,
				fetching: false,
				fetched: false,
			},
		}));

		builder.addCase(fetchItemizedBillingEntries.pending, state => ({
			...state,
			itemizedBill: {
				...state.itemizedBill,
				fetching: true,
				fetched: false,
			},
		}));

		builder.addCase(fetchItemizedBillingEntries.fulfilled, (state, payload) => ({
			...state,
			itemizedBill: {
				entries: payload.payload.items.map(item => ({
					...item,
					timestamp: DateTime.fromISO(item.timestamp).toMillis(),
					amountInCredits: item.amountInCredits,
				})),
				fetching: false,
				fetched: true,
			},
		}));

		builder.addCase(fetchItemizedBillingSettings.pending, state => ({
			...state,
			itemizedBillSettings: { ...state.itemizedBillSettings, fetching: true, fetched: false },
		}));

		builder.addCase(fetchItemizedBillingSettings.fulfilled, (state, action) => ({
			...state,
			itemizedBillSettings: {
				settings: action.payload,
				fetching: false,
				fetched: true,
			},
		}));

		builder.addCase(fetchItemizedBillingSettings.rejected, state => ({
			...state,
			itemizedBillSettings: {
				...state.itemizedBillSettings,
				fetching: false,
				fetched: false,
			},
		}));

		builder.addCase(updateItemizedBillingSettings.pending, state => ({
			...state,
			itemizedBillSettings: { ...state.itemizedBillSettings, fetching: true },
		}));

		builder.addCase(updateItemizedBillingSettings.fulfilled, (state, action) => ({
			...state,
			itemizedBillSettings: {
				settings: action.meta.arg,
				fetching: false,
				fetched: true,
			},
		}));

		builder.addCase(updateItemizedBillingSettings.rejected, state => ({
			...state,
			itemizedBillSettings: {
				...state.itemizedBillSettings,
				fetching: false,
				fetched: false,
			},
		}));

		builder.addCase(fetchBillingFlags.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
		}));

		builder.addCase(fetchBillingFlags.fulfilled, (state, action) => ({
			...state,
			billingFlags: {
				flags: action.payload,
				fetching: false,
				fetched: true,
			},

			fetching: false,
			fetched: true,
		}));

		builder.addCase(fetchBillingFlags.rejected, state => ({
			...state,
			fetching: false,
			fetched: false,
		}));
	},
});

export const reducer = slice.reducer;
