import { handleActions } from '../..';
import * as actions from './actions';
import { IvrState } from './types';

const initialState: IvrState = {
	fetched: false,
	fetching: false,
	items: [],
};

export default handleActions<IvrState, PossibleActions<typeof actions>>(
	{
		IVRS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		IVRS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload,
		}),
		IVR_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: state.items.concat(payload),
		}),
		IVR_RENAME_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(ivr => (ivr.id === data.id ? { ...ivr, alias: data.alias } : ivr)),
		}),
		IVR_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(ivr => ivr.id !== data.id),
		}),
		IVR_ASSIGN_PHONE_NUMBERS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(ivr =>
				ivr.id === data.id ? { ...ivr, phoneNumbers: data.phoneNumbers } : ivr
			),
		}),
		IVR_ASSIGN_AUDIO_FILE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(ivr =>
				ivr.id === data.id
					? {
							...ivr,
							configuration: {
								...ivr.configuration,
								[data.endpoint]: { type: 'ANNOUNCEMENT', value: data.audioFileId },
							},
						}
					: ivr
			),
		}),
	},
	initialState
);
