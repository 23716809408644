import { createAction } from '../..';
import api from '../../../api';
import { ApiConfigurationKey } from '../../../api/types/ivr';
import { ReduxState } from '../../types';

const shouldFetchIvrs = (state: ReduxState) => !state.ivrs.fetched && !state.ivrs.fetching;

export const fetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
	shouldFetch: shouldFetchIvrs,
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const fetchIvrAnnouncements = createAction('IVRS_FETCH_ANNOUNCEMENTS', () => ({
	promise: () => api.getIvrs(),
	shouldFetch: shouldFetchIvrs,
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const forceFetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const createIvr = createAction('IVR_CREATE', ({ alias }: { alias: string }) => ({
	promise: () => api.createIvr(alias),
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const assignPhoneNumbersToIvr = createAction(
	'IVR_ASSIGN_PHONE_NUMBERS',
	({ ivrId, phoneNumbers }: { ivrId: string; phoneNumbers: string[] }) => ({
		promise: () => api.assignPhoneNumbersToIvr(ivrId, phoneNumbers),
		restrictions: {
			restrictions: ['CAN_CREATE_IVR'],
		},

		data: {
			id: ivrId,
			phoneNumbers,
		},
	})
);

export const assignAudioFileToIvr = createAction(
	'IVR_ASSIGN_AUDIO_FILE',
	({
		ivrId,
		audioFileId,
		endpoint,
	}: {
		ivrId: string;
		audioFileId: string;
		endpoint: ApiConfigurationKey;
	}) => ({
		promise: () => api.assignAudioFileToIvr(ivrId, audioFileId, endpoint, true),
		restrictions: {
			restrictions: ['CAN_CREATE_IVR'],
		},
		data: {
			id: ivrId,
			audioFileId,
			endpoint,
		},
	})
);

export const renameIVR = createAction(
	'IVR_RENAME',
	({ ivrId, alias }: { ivrId: string; alias: string }) => ({
		promise: () => api.renameIvr(ivrId, alias),
		restrictions: {
			restrictions: ['CAN_CREATE_IVR'],
		},
		data: {
			id: ivrId,
			alias,
		},
	})
);

export const deleteIVR = createAction('IVR_DELETE', ({ ivrId }: { ivrId: string }) => ({
	promise: () => api.deleteIvr(ivrId),
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
	data: {
		id: ivrId,
	},
}));

export const setIvrKeyExtensionMapping = createAction(
	'IVR_UPDATE_KEY_MAPPING',
	({
		ivrId,
		forwardingKey,
		extension,
	}: {
		ivrId: string;
		forwardingKey: string;
		extension: string;
	}) => ({
		promise: () => api.updateIvrKeyExtensionMapping(ivrId, forwardingKey, extension),
		restrictions: {
			restrictions: ['CAN_CREATE_IVR'],
		},
		data: {
			ivrId,
			forwardingKey,
			extension,
		},
	})
);

export const setIvrKeyPhonenumberMapping = createAction(
	'IVR_UPDATE_KEY_MAPPING',
	({
		ivrId,
		forwardingKey,
		phonenumber,
	}: {
		ivrId: string;
		forwardingKey: string;
		phonenumber: string;
	}) => ({
		promise: () => api.updateIvrKeyPhonenumberMapping(ivrId, forwardingKey, phonenumber),
		restrictions: {
			restrictions: ['CAN_CREATE_IVR'],
		},
		data: {
			ivrId,
			forwardingKey,
			phonenumber,
		},
	})
);
