import api from '../../../api';
import { ApiBase64Upload } from '../../../api/types/identityVerification';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchUsersAvatars = createAsyncThunk(
	'avatars/fetchUsersAvatars',
	async (_force?: boolean) => api.getUsersAvatars(),
	{
		condition: (arg, { getState }) => {
			return (
				arg === true ||
				(getState().avatars.fetchingForUser.length === 0 &&
					getState().avatars.fetchedForUser.length === 0)
			);
		},
	}
);

export const fetchUserAvatar = createAsyncThunk(
	'avatars/fetchUserAvatar',
	async (arg: { webuserId: string; force?: boolean }) => api.getUserAvatarForWebuser(arg.webuserId),
	{
		condition: (arg, { getState }) =>
			getState().avatars.fetchingForUser.indexOf(arg.webuserId) === -1 || arg.force === true,
	}
);

export const uploadUserAvatar = createAsyncThunk(
	'avatars/uploadUserAvatar',
	async (arg: { webuserId: string; base64Content: string }) => {
		const request = {
			name: 'avatar.png',
			content: arg.base64Content.split(',')[1],
		} as ApiBase64Upload;
		return api.uploadUserAvatar(arg.webuserId, request);
	},
	{
		condition: (_arg, { getState }) => !getState().avatars.uploading,
	}
);

export const deleteUserAvatar = createAsyncThunk(
	'avatars/deleteUserAvatar',
	async (webuserId: string) => {
		return api.deleteUserAvatar(webuserId);
	}
);
