import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	cancelContract,
	revokeContractCancellation,
	deleteRoutings,
	fetchPhonenumbers,
	forceFetchPhonenumbers,
	setRoutings,
	releaseMobilePhonenumber,
} from './actions';
import { SliceData, useAction } from '../../index';
import { Phonenumber } from './types';

export const usePhonenumbers = (): SliceData<Phonenumber[]> => {
	const dispatch = useDispatch();
	const phonenumbers = useSelector(state => state.phonenumbers);

	useEffect(() => {
		dispatch(fetchPhonenumbers());
	}, [dispatch]);

	if (phonenumbers.fetched) {
		return {
			data: phonenumbers.items,
			fetched: true,
		};
	}

	return {
		data: null,
		fetched: false,
	};
};

export const usePhonenumbersActions = () => ({
	setRoutings: useAction(setRoutings),
	deleteRoutings: useAction(deleteRoutings),
	cancelContract: useAction(cancelContract),
	revokeContractCancellation: useAction(revokeContractCancellation),
	forceFetchPhonenumbers: useAction(forceFetchPhonenumbers),
	releaseMobilePhonenumber: useAction(releaseMobilePhonenumber),
});
