import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment/moment';
import { isSipgateAppWithSubscription, isTrial, SipgateApp, SipgateAppState } from './types';
import {
	cancelSipgateApp,
	createSipgateApps,
	fetchSipgateApp,
	fetchSipgateApps,
	revokeCancelledSipgateApp,
	upgradedSipgateApp,
} from './actions';

export const initialState: SipgateAppState = {
	items: [],
	fetched: false,
	fetching: false,
	fetchingErrorsForWebuser: [],
	fetchingForWebuser: [],
	fetchedForWebuser: [],
	upgradedForWebuser: [],
	upgradingForWebuser: [],
	creatingForWebuser: [],
	createdForWebuser: [],
	canceledForWebuser: [],
	cancelingForWebuser: [],
};

const slice = createSlice({
	name: 'sipgateApp',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSipgateApps.pending, state => ({
			...state,
			fetchingForWebuser: ['*'],
			fetching: true,
		}));

		builder.addCase(fetchSipgateApp.pending, (state, { meta }) => ({
			...state,
			fetchingForWebuser: [...state.fetchingForWebuser, meta.arg.webuserId],
			fetching: true,
		}));

		builder.addCase(fetchSipgateApps.rejected, (state, { payload }) => {
			if (payload) {
				return {
					...state,
					fetching: false,
					fetchingErrorsForWebuser: [
						...state.fetchingErrorsForWebuser,
						{ webuserId: '*', error: payload },
					],
				};
			}
			return { ...state };
		});

		builder.addCase(fetchSipgateApp.rejected, (state, { payload }) => {
			if (payload) {
				return {
					...state,
					fetching: false,
					fetchingErrorsForWebuser: [
						...state.fetchingErrorsForWebuser,
						{ webuserId: payload.webuserId, error: payload },
					],
				};
			}
			return { ...state };
		});

		builder.addCase(upgradedSipgateApp.pending, (state, { meta }) => ({
			...state,
			upgradingForWebuser: [...state.upgradingForWebuser, meta.arg.userId],
		}));
		builder.addCase(upgradedSipgateApp.fulfilled, (state, { meta }) => {
			const index = state.items.findIndex(sipgateApp => sipgateApp.userId === meta.arg.userId);
			const item = state.items[index];

			const updatedItem = {
				...item,
				activeBooking: {
					...item.activeBooking,
					subscriptionName: 'PREMIUM',
				},
			} as SipgateApp;

			const upgradingIndex = state.upgradedForWebuser.findIndex(
				userId => userId === meta.arg.userId
			);
			return {
				...state,
				items: [...state.items.slice(0, index), updatedItem, ...state.items.slice(index + 1)],
				upgradingForWebuser: [
					...state.upgradingForWebuser.slice(0, upgradingIndex),
					...state.upgradingForWebuser.slice(index + 1),
				],
				upgradedForWebuser: [...state.upgradedForWebuser, meta.arg.userId],
			};
		});

		builder.addCase(createSipgateApps.pending, (state, { meta }) => ({
			...state,
			creatingForWebuser: [...state.creatingForWebuser, ...meta.arg.userIds],
		}));
		builder.addCase(createSipgateApps.fulfilled, (state, { meta }) => {
			return {
				...state,
				creatingForWebuser: [],
				createdForWebuser: [...state.createdForWebuser, ...meta.arg.userIds],
			};
		});

		builder.addCase(cancelSipgateApp.pending, (state, { meta }) => ({
			...state,
			cancelingForWebuser: [...state.cancelingForWebuser, meta.arg.userId],
		}));
		builder.addCase(cancelSipgateApp.fulfilled, (state, { meta }) => {
			const index = state.items.findIndex(sipgateApp => sipgateApp.userId === meta.arg.userId);

			if (index === -1) {
				return {
					...state,
				};
			}

			let updatedItem: SipgateApp;

			const item = state.items[index];
			if (isSipgateAppWithSubscription(item) && isTrial(item)) {
				updatedItem = {
					...item,
					activeBooking: {
						...item.activeBooking,
						trial: {
							...item.activeBooking.trial,
							isCancelled: true,
						},
					},
				} as SipgateApp;
			} else {
				updatedItem = {
					...item,
					activeBooking: {
						...item.activeBooking,
						terminatedFor: moment().endOf('month').toISOString(),
					},
				} as SipgateApp;
			}

			const cancellingIndex = state.cancelingForWebuser.findIndex(
				userId => userId === meta.arg.userId
			);
			return {
				...state,
				items: [...state.items.slice(0, index), updatedItem, ...state.items.slice(index + 1)],
				cancelingForWebuser: [
					...state.cancelingForWebuser.slice(0, cancellingIndex),
					...state.cancelingForWebuser.slice(index + 1),
				],
				canceledForWebuser: [...state.canceledForWebuser, meta.arg.userId],
			};
		});

		builder.addCase(revokeCancelledSipgateApp.fulfilled, (state, { meta }) => {
			const index = state.items.findIndex(sipgateApp => sipgateApp.userId === meta.arg.userId);
			const item = state.items[index];

			let updatedItem: SipgateApp;
			if (isSipgateAppWithSubscription(item) && isTrial(item)) {
				updatedItem = {
					...item,
					activeBooking: {
						...item.activeBooking,
						terminatedFor: undefined,
						trial: {
							...item.activeBooking.trial,
							isCancelled: false,
						},
					},
				} as SipgateApp;
			} else {
				updatedItem = {
					...item,
					activeBooking: {
						...item.activeBooking,
						terminatedFor: undefined,
					},
				} as SipgateApp;
			}

			return {
				...state,
				items: [...state.items.slice(0, index), updatedItem, ...state.items.slice(index + 1)],
			};
		});

		builder.addMatcher(
			isAnyOf(fetchSipgateApps.fulfilled, fetchSipgateApp.fulfilled),
			(state, { payload }) => {
				const sipgateApps = Array.isArray(payload) ? payload : [payload];

				const fetchedForWebuserSet = new Set<string>(state.fetchedForWebuser);
				sipgateApps.forEach(app => fetchedForWebuserSet.add(app.userId));

				let items: SipgateApp[] = [...state.items];
				let fetchingForWebuser = [...state.fetchingForWebuser];
				sipgateApps.forEach(sipgateApp => {
					let index = items.findIndex(item => item.userId === sipgateApp.userId);
					if (index > -1) {
						items = [...items.slice(0, index), sipgateApp, ...items.slice(index + 1)];
					} else {
						items = [...items, sipgateApp];
					}

					index = fetchingForWebuser.findIndex(userId => userId === sipgateApp.userId);
					if (index > -1) {
						fetchingForWebuser = [
							...fetchingForWebuser.slice(0, index),
							...fetchingForWebuser.slice(index + 1),
						];
					}
				});

				const uniqueIds = new Set<string>(items.map(item => item.userId));
				const uniqueItems = Array.from(uniqueIds)
					.map(id => items.find(item => item.userId === id))
					.filter(app => app !== undefined) as SipgateApp[];

				return {
					...state,
					items: [...uniqueItems],
					fetching: false,
					fetched: true,
					fetchingForWebuser: [...Array.from(fetchingForWebuser).sort()],
					fetchingErrorsForWebuser: [],
					fetchedForWebuser: [...Array.from(fetchedForWebuserSet).sort()],
				};
			}
		);
	},
});

export const reducer = slice.reducer;
