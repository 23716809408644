import { createSlice } from '@reduxjs/toolkit';
import { QuickDialsState } from './types';
import {
	createQuickDial,
	deleteQuickDial,
	fetchQuickDials,
	routeQuickDial,
	editQuickDial,
} from './actions';

const initialState: QuickDialsState = {
	fetched: false,
	fetching: false,
	items: [],
};

export const reducer = createSlice({
	name: 'quickDials',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchQuickDials.pending, state => {
			return { ...state, fetching: true };
		});

		builder.addCase(fetchQuickDials.fulfilled, (state, action) => {
			const quickDials = action.payload.items.filter(n => n.type === 'QUICKDIAL');
			return {
				fetched: true,
				fetching: false,
				items: quickDials.map(q => ({
					id: q.id,
					number: q.number,
					endpointId: q.endpointId,
					endpointAlias: q.endpointAlias,
				})),
			};
		});

		builder.addCase(createQuickDial.fulfilled, (state, action) => {
			return {
				...state,
				items: [
					...state.items,
					{
						id: action.payload.id,
						number: action.payload.number,
						endpointId: action.payload.endpointId,
						endpointAlias: action.payload.endpointAlias,
					},
				],
			};
		});

		builder.addCase(editQuickDial.pending, (state, action) => {
			const oldQuickDial = state.items.find(q => q.id === action.meta.arg.numberId);

			if (!oldQuickDial) {
				return state;
			}

			return {
				...state,
				items: [
					...state.items.filter(q => q.id !== action.meta.arg.numberId),
					{
						id: oldQuickDial.id,
						number: action.meta.arg.number,
						endpointId: oldQuickDial.endpointId,
						endpointAlias: '',
					},
				],
			};
		});

		builder.addCase(deleteQuickDial.pending, (state, action) => {
			return {
				...state,
				items: state.items.filter(q => q.id !== action.meta.arg.numberId),
			};
		});

		builder.addCase(routeQuickDial.pending, (state, action) => {
			const oldQuickDial = state.items.find(q => q.id === action.meta.arg.numberId);

			if (!oldQuickDial) {
				return state;
			}

			return {
				...state,
				items: [
					...state.items.filter(q => q.id !== action.meta.arg.numberId),
					{
						id: action.meta.arg.numberId,
						number: oldQuickDial.number,
						endpointId: action.meta.arg.extensionId ? action.meta.arg.extensionId : '',
						endpointAlias: '',
					},
				],
			};
		});
	},
}).reducer;
