import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { SliceData, useAction } from '../../index';
import { QuickDial } from './types';
import {
	createQuickDial,
	deleteQuickDial,
	fetchQuickDials,
	routeQuickDial,
	editQuickDial,
} from './actions';

export const useQuickDials = (): SliceData<QuickDial[]> => {
	const dispatch = useDispatch();
	const quickDials = useSelector(state => state.quickDials);

	useEffect(() => {
		dispatch(fetchQuickDials());
	}, [dispatch]);

	if (quickDials.fetched) {
		return { data: quickDials.items, fetched: true };
	}

	return { data: null, fetched: false };
};

export const useQuickDialActions = () => ({
	createQuickDial: useAction(createQuickDial),
	editQuickDial: useAction(editQuickDial),
	deleteQuickDial: useAction(deleteQuickDial),
	routeQuickDial: useAction(routeQuickDial),
});
