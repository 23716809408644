import { handleActions } from '../..';
import { TrunkContingent, TrunksState } from './types';
import * as actions from './actions';
import { ApiTrunkContingentContract } from '../../../api/types/trunk';
import { deleteAddress } from '../addresses';

const initialState: TrunksState = {
	items: [],
	fetched: false,
	fetching: false,

	devices: [],
	fetchingDevicesForTrunk: [],
	fetchedDevicesForTrunk: [],

	contingents: [],
	fetchingContingentsForTrunk: [],
	fetchedContingentsForTrunk: [],
};

const removeItemFromArray = (arr: readonly string[], deviceId: string) =>
	arr.filter(item => item !== deviceId);

const formatTrunkContingents = (
	trunkId: string,
	trunkContingentContracts: ApiTrunkContingentContract[]
): TrunkContingent[] => {
	return trunkContingentContracts.flatMap(contract =>
		contract.contingents.map(contingent => ({
			trunkId,
			name: contingent.type,
			type: contingent.type.toLowerCase().includes('mobile') ? 'MOBILE' : 'LANDLINE',
			amount: contingent.amount,
			used: contingent.used,
			cancellationDate: contract.cancellationDate ? new Date(contract.cancellationDate) : null,
			activationDate: contract.activationDate ? new Date(contract.activationDate) : null,
			disabledCancellationOrRevocation: contract.disabledCancellationOrRevocation,
			productId: contract.productId,
			customerProductId: contract.contractId,
			productName: contract.productName,
		}))
	);
};

export default handleActions<TrunksState, PossibleActions<typeof actions | typeof deleteAddress>>(
	{
		TRUNKS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		TRUNKS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload.items,
			fetched: true,
			fetching: false,
		}),

		TRUNK_DEVICES_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingDevicesForTrunk: [
				...removeItemFromArray(state.fetchingDevicesForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNK_DEVICES_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			devices: [
				...state.devices.filter(d => d.trunkId !== data.trunkId),
				...payload.items.map(p => ({
					...p,
					trunkId: data.trunkId,
				})),
			],
			fetchingDevicesForTrunk: [
				...removeItemFromArray(state.fetchingDevicesForTrunk, data.trunkId),
			],
			fetchedDevicesForTrunk: [
				...removeItemFromArray(state.fetchedDevicesForTrunk, data.trunkId),
				data.trunkId,
			],
		}),

		TRUNK_CONTINGENTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingContingentsForTrunk: [
				...removeItemFromArray(state.fetchingContingentsForTrunk, data.trunkId),
				data.trunkId,
			],
		}),
		TRUNK_CONTINGENTS_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			contingents: [
				...state.contingents.filter(d => d.trunkId !== data.trunkId),
				...formatTrunkContingents(data.trunkId, payload.items),
			],
			fetchingContingentsForTrunk: removeItemFromArray(
				state.fetchingContingentsForTrunk,
				data.trunkId
			),
			fetchedContingentsForTrunk: [
				...removeItemFromArray(state.fetchedContingentsForTrunk, data.trunkId),
				data.trunkId,
			],
		}),

		TRUNKS_RENAME_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.id) {
					return item;
				}

				return {
					...item,
					alias: data.alias,
				};
			}),
		}),

		TRUNKS_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.id),
		}),

		TRUNKS_SET_CALLER_ID_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					fallbackCallerE164Number: data.callerE164Number,
				};
			}),
		}),

		TRUNKS_SET_EMERGENCY_LOCATION_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.trunkId) {
					return item;
				}

				return {
					...item,
					emergencyAddressId: data.addressId,
				};
			}),
		}),

		NEW_ADDRESS_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.emergencyAddressId !== data.addressId.toString()) {
					return item;
				}

				return {
					...item,
					emergencyAddressId: data.emergencyAddressId ? data.emergencyAddressId.toString() : '',
				};
			}),
		}),
	},
	initialState
);
