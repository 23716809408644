import React from 'react';
import { PandaContext } from '@panda/ui';
import { useTranslate } from '../redux/slices/translations';

const PandaProvider = ({ children }: { children: React.ReactNode }) => {
	const translate = useTranslate();
	const portalRoot = document.getElementById('overlay-portal')!;

	return (
		<PandaContext.Provider
			value={{
				languageKeys: {
					PANDA_FILEUPLOAD_INVALID_FILESIZE: translate('PANDA_FILEUPLOAD_INVALID_FILESIZE'),
					PANDA_FILEUPLOAD_INVALID_FILETYPE: translate('PANDA_FILEUPLOAD_INVALID_FILETYPE'),
					PANDA_PAGINATION_OF_DIVIDER: translate('PANDA_PAGINATION_OF_DIVIDER'),
					PANDA_PAGINATION_ENTRIES_PER_PAGE: translate('PANDA_PAGINATION_ENTRIES_PER_PAGE'),
					PANDA_CONTEXTMENU_LABEL: translate('PANDA_CONTEXTMENU_LABEL'),
					PANDA_CONTEXTMENU_ARIA_LABEL: translate('PANDA_CONTEXTMENU_ARIA_LABEL'),
					PANDA_LISTNAV_OF: translate('PANDA_LISTNAV_OF'),
					PANDA_LISTNAV_COUNT: translate('PANDA_LISTNAV_COUNT'),
					PANDA_LISTNAV_SORT_BY: translate('PANDA_LISTNAV_SORT_BY'),
					PANDA_LIST_VIEW_ACTIONS: translate('PANDA_LIST_VIEW_ACTIONS'),
					PANDA_LISTNAV_TOTAL: translate('PANDA_LISTNAV_TOTAL'),
					PANDA_DETAIL_VIEW_SECTION_COUNT: translate('PANDA_DETAIL_VIEW_SECTION_COUNT'),
					PANDA_DETAIL_VIEW_SECTION_TOTAL: translate('PANDA_DETAIL_VIEW_SECTION_TOTAL'),
					PANDA_SEARCH_INPUT_CLEAR: translate('PANDA_SEARCH_INPUT_CLEAR'),
					PANDA_SEARCH_INPUT_LABEL: translate('PANDA_SEARCH_INPUT_LABEL'),
					PANDA_SEARCH_RESULTS: translate('PANDA_SEARCH_RESULTS'),
					PANDA_SEARCH_NO_RESULTS_HEADING: translate('PANDA_SEARCH_NO_RESULTS_HEADING'),
					PANDA_SEARCH_NO_RESULTS_PARAGRAPH: replacement =>
						translate('PANDA_SEARCH_NO_RESULTS_PARAGRAPH', replacement),
					PANDA_SEARCH_NO_RESULTS_SUBHEADING: translate('PANDA_SEARCH_NO_RESULTS_SUBHEADING'),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1: translate('PANDA_SEARCH_NO_RESULTS_LIST_ITEM_1'),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2: translate('PANDA_SEARCH_NO_RESULTS_LIST_ITEM_2'),
					PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3: translate('PANDA_SEARCH_NO_RESULTS_LIST_ITEM_3'),
					PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT: translate(
						'PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT'
					),
					PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT: translate(
						'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT'
					),
					PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX: translate(
						'PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX'
					),
					PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL: translate('PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL'),
					PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL: translate(
						'PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL'
					),
					PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL: translate(
						'PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL'
					),
					PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX: translate(
						'PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX'
					),
					PANDA_ICON_INTERNAL_LINK: translate('PANDA_ICON_INTERNAL_LINK'),
					PANDA_ICON_COPY: translate('PANDA_ICON_COPY'),
					PANDA_ICON_CLOSE: translate('PANDA_ICON_CLOSE'),
					PANDA_ICON_DELETE: translate('PANDA_ICON_DELETE'),
					PANDA_ICON_DOWNLOAD: translate('PANDA_ICON_DOWNLOAD'),
					PANDA_ICON_EXTERNAL_LINK: translate('PANDA_ICON_EXTERNAL_LINK'),
					PANDA_ICON_MAIL: translate('PANDA_ICON_MAIL'),
					PANDA_ICON_PHONE: translate('PANDA_ICON_PHONE'),
					PANDA_ICON_FILE: translate('PANDA_ICON_FILE'),
					PANDA_ICON_INFO: translate('PANDA_ICON_INFO'),
					PANDA_ICON_MORE: translate('PANDA_ICON_MORE'),
					PANDA_ICON_ADD: translate('PANDA_ICON_ADD'),
					PANDA_ICON_SEARCH: translate('PANDA_ICON_SEARCH'),
					PANDA_ICON_EDIT: translate('PANDA_ICON_EDIT'),
					PANDA_ICON_EXPORT: translate('PANDA_ICON_EXPORT'),
					PANDA_ICON_BACK: translate('PANDA_ICON_BACK'),
					PANDA_ICON_NEXT: translate('PANDA_ICON_NEXT'),
					PANDA_ICON_DOWN: translate('PANDA_ICON_DOWN'),
					PANDA_ICON_UP: translate('PANDA_ICON_UP'),
					PANDA_ICON_SHOW_PASSWORD: translate('PANDA_ICON_SHOW_PASSWORD'),
					PANDA_ICON_HIDE_PASSWORD: translate('PANDA_ICON_HIDE_PASSWORD'),
					PANDA_SIDEBAR_NAVIGATION_LANDMARK: translate('PANDA_SIDEBAR_NAVIGATION_LANDMARK'),
					PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK: translate(
						'PANDA_SIDEBAR_NAVIGATION_SKIP_TO_CONTENT_LINK'
					),
					PANDA_AVATAR_FROM: translate('PANDA_AVATAR_FROM'),
					PANDA_AVATAR_REMAINING_COUNT: translate('PANDA_AVATAR_REMAINING_COUNT'),
					PANDA_PASSWORD_INPUT_SHOWN: translate('PANDA_PASSWORD_INPUT_SHOWN'),
					PANDA_PASSWORD_INPUT_HIDDEN: translate('PANDA_PASSWORD_INPUT_HIDDEN'),
					PANDA_SELECT_PLACEHOLDER: translate('PLACEHOLDER_SELECT_AN_OPTION'),
					PANDA_TITLE_SUFFIX: translate('PANDA_TITLE_SUFFIX'),
					PANDA_INPUT_OPTIONAL: translate('PANDA_INPUT_OPTIONAL'),
					PANDA_COMBOBOX_OVERFLOW: overflow => translate('PANDA_COMBOBOX_OVERFLOW', overflow),
					PANDA_ADD_LOGO: translate('PANDA_ADD_LOGO'),
					PANDA_CHANGE_LOGO: translate('PANDA_CHANGE_LOGO'),
					PANDA_ICON_RENEW_INVOICE: translate('PANDA_ICON_RENEW_INVOICE'),
				},
				portalRoot,
			}}
		>
			{children}
		</PandaContext.Provider>
	);
};

export default PandaProvider;
