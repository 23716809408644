import {
	hasRoutingPhoneline,
	isPhonenumberBlock,
	isPhonenumberInOngoingImportPorting,
	isPhonenumberWithChildren,
	Phonenumber,
} from './types';

export const findPhonenumber = (phonenumbers: Phonenumber[], e164Number: string) => {
	const searchQueue = [...phonenumbers];

	while (searchQueue.length > 0) {
		const phonenumber = searchQueue.shift();

		if (phonenumber && phonenumber.e164Number === e164Number) {
			return phonenumber;
		}

		if (phonenumber && isPhonenumberWithChildren(phonenumber)) {
			searchQueue.push(...phonenumber.numbers);
		}
	}

	return undefined;
};

export const flatMapRoutablePhonenumbers = <T = Phonenumber>(
	phonenumbers: Phonenumber[],
	mapFunction?: (number: Phonenumber) => T
): T[] => {
	const defaultMapFunction = (number: Phonenumber): T => number as T;
	const actualMapFunction = mapFunction || defaultMapFunction;

	return phonenumbers.flatMap(n => {
		if (isPhonenumberWithChildren(n)) {
			if (n.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT') {
				return [actualMapFunction(n), ...flatMapRoutablePhonenumbers(n.numbers, actualMapFunction)];
			}
			return flatMapRoutablePhonenumbers(n.numbers, actualMapFunction);
		}
		return actualMapFunction(n);
	});
};

export const selectPhonenumbersForWebusers = (
	phonenumbers: Phonenumber[],
	webuserIds: string[]
): Phonenumber[] =>
	phonenumbers.flatMap(phonenumber => {
		if (isPhonenumberWithChildren(phonenumber)) {
			const parentHasUserRouting =
				hasRoutingPhoneline(phonenumber) && webuserIds.includes(phonenumber.routing.targetOwner);

			return parentHasUserRouting
				? [phonenumber, ...selectPhonenumbersForWebusers(phonenumber.numbers, webuserIds)]
				: [...selectPhonenumbersForWebusers(phonenumber.numbers, webuserIds)];
		}

		if (hasRoutingPhoneline(phonenumber) && webuserIds.includes(phonenumber.routing.targetOwner)) {
			return [phonenumber];
		}

		return [];
	});

export const isCancelablePhonenumber = (number: Phonenumber, type?: string) => {
	if (type === 'single' && number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT') {
		return (
			number.baseContract &&
			number.baseContract.nextCancellationDate &&
			!number.baseContract.cancellation
		);
	}

	return number.contract && number.contract.nextCancellationDate && !number.contract.cancellation;
};

export const isRevokablePhonenumber = (number: Phonenumber, type?: 'single' | 'multiple') => {
	if (
		/*
		 * Wants to revoke prolongation so we need to look for the basecontract
		 * and when there is a cancellation we can't revoke the children contract.
		 */
		(type === 'multiple' &&
			number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			number.baseContract &&
			number.baseContract.cancellation) ||
		/*
		 * Wants to revoke preprolongation so we need to look for the basecontract
		 * and when there is no cancellation we can't revoke it.
		 */
		(type === 'single' &&
			number.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			number.baseContract &&
			!number.baseContract.cancellation)
	) {
		return false;
	}

	return number.contract && number.contract.cancellation && number.contract.cancellation.revocable;
};

export const findPhonenumbersNotInOngoingImportPorting = (numbers: Phonenumber[]) => {
	return numbers.filter(phonenumber => {
		if (isPhonenumberBlock(phonenumber)) {
			return phonenumber.numbers.every(number => !isPhonenumberInOngoingImportPorting(number));
		}
		return !isPhonenumberInOngoingImportPorting(phonenumber);
	});
};
