import { DateTime } from 'luxon';

import { Currency } from '../../../components/bookables/prices/util';

export interface Statement {
	description: string;
	price: {
		creditingType: 'NEGATIVE' | 'POSITIVE';
		grossPrice: number;
		currency: 'EUR' | 'GBP';
	};
	timestamp: string;
	periodStart: number;
	periodEnd: number;
}

export interface AggregatedStatement {
	description: string;
	price: {
		creditingType: 'NEGATIVE' | 'POSITIVE';
		grossPrice: number;
		currency: 'EUR' | 'GBP';
	};
	quantity: number;
	totalDurationInSeconds: number;
	transactionType: 'CALL' | 'SMS' | 'FAX' | 'API_CALL';
	periodStart: number;
	periodEnd: number;
}

export interface PeriodBalance {
	periodStart: number;
	periodEnd: number;
	balanceStart: {
		amount: number;
		currency: 'EUR' | 'GBP';
	};
	balanceEnd: {
		amount: number;
		currency: 'EUR' | 'GBP';
	};
}

export interface ApiStatement {
	description: string;
	price: {
		creditingType: 'NEGATIVE' | 'POSITIVE';
		grossPrice: number;
		currency: 'EUR' | 'GBP';
	};
	timestamp: string;
}

export interface ApiAggregatedStatement {
	description: string;
	price: {
		creditingType: 'NEGATIVE' | 'POSITIVE';
		grossPrice: number;
		currency: 'EUR' | 'GBP';
	};
	quantity: number;
	totalDurationInSeconds: number;
	transactionType: 'CALL' | 'SMS' | 'FAX' | 'API_CALL';
}

export interface ApiAccountStatement {
	statements: ApiStatement[];
	aggregatedStatements: ApiAggregatedStatement[];
	periodStart: string;
	periodEnd: string;
	balanceStart: {
		amount: number;
		currency: 'EUR' | 'GBP';
	};
	balanceEnd: {
		amount: number;
		currency: 'EUR' | 'GBP';
	};
}

export type InvoiceType =
	| 'COMMISSION_PAYOUT'
	| 'COMMISSION_RECEIPT'
	| 'CORRECTION'
	| 'CREDITS'
	| 'FEE'
	| 'GOODWILL_CREDITING'
	| 'INVOICE'
	| 'NEGATIVE'
	| 'PAYOUT'
	| 'REMINDER'
	| 'REVOCATION'
	| 'VOUCHER'
	| 'NONPAYER_CREDITING'
	| 'MIGRATION';

export interface Invoice {
	invoiceId: string;
	documentId: string;
	amountInCredits: number;
	currency: 'EUR' | 'GBP';
	type: InvoiceType;
	date: string;
	recreationAllowed: boolean;
	replacedByInvoiceId?: string;
	replacesInvoiceId?: string;
}

export const itemizedBillEntryTypes = ['VOICE', 'FAX', 'API_CALL', 'TEXT'] as const;
export type ItemizedBillEntryTypeOption = (typeof itemizedBillEntryTypes)[number];
export type ItemizedBillWebuserId = `w${number}`;

export interface ApiItemizedBillingEntry {
	timestamp: string;
	tariff: string;
	initiator: string;
	destination: string;
	targetPhoneNumber: string;
	durationInSeconds: number;
	type: ItemizedBillEntryTypeOption;
	amountInCredits: number;
	currency: Currency;
	extension: string;
	owners: ItemizedBillWebuserId[];
}

export interface ApiItemizedBillResult {
	periodStart: DateTime;
	periodEnd: DateTime;
	items: ApiItemizedBillingEntry[];
}

export const itemizedBillingPhoneNumberFormats = ['FULL', 'MASKED', 'NONE'] as const;

export type ItemizedBillingPhoneNumberFormat = (typeof itemizedBillingPhoneNumberFormats)[number];

export interface ApiItemizedBillingSettings {
	phoneNumberFormat: ItemizedBillingPhoneNumberFormat;
}

export interface ApiBillingFlags {
	hasHighTrafficFlag: boolean;
}

export interface BillingFlags {
	hasHighTrafficFlag: boolean;
}

export interface ItemizedBillEntry {
	timestamp: number;
	tariff: string;
	initiator: string;
	destination: string;
	targetPhoneNumber: string;
	durationInSeconds: number;
	type: ItemizedBillEntryTypeOption;
	amountInCredits: number;
	currency: Currency;
	owners: ItemizedBillWebuserId[];
}

export interface ItemizedBillSettings {
	phoneNumberFormat: ItemizedBillingPhoneNumberFormat;
}

export interface AccountingState {
	accountStatement: {
		statements: Statement[];
		aggregatedStatements: AggregatedStatement[];
		balances: PeriodBalance[];
		fetching: boolean;
		fetched: boolean;
	};

	itemizedBill: {
		entries: ItemizedBillEntry[];
		fetching: boolean;
		fetched: boolean;
	};

	itemizedBillSettings: {
		settings: ItemizedBillSettings;
		fetching: boolean;
		fetched: boolean;
	};

	invoices: {
		invoices: Invoice[];
		fetching: boolean;
		fetched: boolean;
	};

	billingFlags: {
		flags: BillingFlags;
		fetching: boolean;
		fetched: boolean;
	};

	billingAddress: {
		billingAddress: BillingAddress;
		fetching: boolean;
		fetched: boolean;
	};
}

export interface BillingAddress {
	gender: string;
	firstname: string;
	middleInitial: string;
	lastname: string;
	company: string;
	email: string;
	street: string;
	zip: string;
	city: string;
	state: string;
	country: string;
	address2: string;
	vatId: string;
}
