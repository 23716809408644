import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchNumbers(state: ReduxState) {
	return !state.numbers.fetching && !state.numbers.fetched;
}

export const fetchAccessibleNumbers = createAction(
	'NUMBERS_FETCH_ACCESSIBLE',
	(force?: boolean) => ({
		promise: () => {
			return Promise.all([api.getAllNumbers(), api.getAcds().catch(() => ({ items: [] }))]);
		},
		shouldFetch: (state: ReduxState) => force || shouldFetchNumbers(state),
	})
);

export const forceFetchAccessibleNumbers = () => fetchAccessibleNumbers(true);
