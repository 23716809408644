import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classes from './TacView.scss';
import { useProductsTacs } from '../../redux/modules/productsTacs/hooks';
import SipgateLogo from '../../media/logo/sipgate_wort-bild-marke_schwarz.svg';
import CenteredLogoSpinner from '../../components/spinner/CenteredLogoSpinner';

const Tac = () => {
	const { productId } = useParams<{ productId: string }>();
	const { fetchProductTacs, items: productsTacs } = useProductsTacs();

	useEffect(() => {
		fetchProductTacs(productId);
	}, [fetchProductTacs, productId]);

	if (!productsTacs || (productsTacs && !productsTacs[productId])) {
		return <CenteredLogoSpinner />;
	}

	return (
		<div className={classes.wrapper}>
			<img
				className={classes.sipgateLogo}
				src={SipgateLogo}
				alt="sipgate logo"
				aria-hidden="true"
			/>
			<main className={classes.content}>
				<div className={classes.success}>
					<div className={classes.content}>
						<span
							className={classes.message}
							// eslint-disable-next-line react/forbid-dom-props, react/no-danger
							dangerouslySetInnerHTML={{ __html: productsTacs[productId][0].text }}
						/>
					</div>
				</div>
			</main>
		</div>
	);
};

export default Tac;
