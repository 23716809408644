import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import { SipgateDomain } from '../../modules/userinfo';

const shouldFetchAppSubscriptionTypes = (state: ReduxState) => {
	return !state.appSubscriptions.fetched && !state.appSubscriptions.fetching;
};

export const fetchAppSubcriptionTypes = createAsyncThunk(
	'appSubscriptionTypes/fetch',
	async (_domain: SipgateDomain) => api.getAppSubscriptionTypes(),
	{
		condition: (_arg, { getState }) => shouldFetchAppSubscriptionTypes(getState()),
	}
);
